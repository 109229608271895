import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PURGE} from 'redux-persist';

type Props = {
  searchfilter: any;
  pageCurrent: number;
  pageSearchLocation: number;
  pageSearchPosition: number;
  pathName: string;
};

const initialState = {
  searchfilter: null,
  pageCurrent: 0,
  pageSearchLocation: 0,
  pageSearchPosition: 0,
  pathName: '',
};

export type FilterReducerState = typeof initialState;

const tempSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Props>) => {
      state.searchfilter = action.payload.searchfilter;
      state.pageCurrent = action.payload.pageCurrent;
      state.pageSearchLocation = action.payload.pageSearchLocation;
      state.pageSearchPosition = action.payload.pageSearchPosition;
      state.pathName = action.payload.pathName;
    },

    setPageInit: (state) => {
      state.searchfilter = null;
      state.pageCurrent = 0;
      state.pageSearchLocation = 0;
      state.pageSearchPosition = 0;
      state.pathName = '';
    },
  },

  //   extraReducers: (builder) => {
  //     //초기화하고 싶은 persist state가 있는 slice마다 추가해야한다.
  //     builder.addCase(PURGE, () => initialState);
  //   },
});

export const {setPage, setPageInit} = tempSlice.actions;
export default tempSlice.reducer;
