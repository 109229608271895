import '../styles/globals.css';

import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider, setLogger} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {useState} from 'react';
import PageWithLayoutType from 'types/pageWithLayout';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from 'stores';
import Axios from 'api/';
import AuthWrapper from '@layouts/AuthWrapper';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import {DefaultSeo} from 'next-seo';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

Axios.interceptor(store);
// axios 에러시 크롬에서 console 없애기
setLogger({
  log: console.log,
  warn: console.warn,
  error: () => {
    null;
  }, // do nothing
});

type AppLayoutProps = {
  Component: PageWithLayoutType;
  pageProps: any;
};

// const DEFAULT_SEO = {
//   title: "meta head title에 들어가는 값",
//   description: "meta head description에 들어가는 값",
//   canonical: "https://www.carrotins.com",
//   openGraph: {
//     type: "website",
//     locale: "ko_KR",
//     url: "카카오톡, 페이스북에 링크 넣으면 연결되는 url",
//     title: "카카오톡, 페이스북에 링크 넣으면 올라올 타이틀",
//     site_name: "사이트이름",
//     images: [
//       {
//         url: "카카오톡, 페이스북에에 링크 넣으면 올라올 이미지",
//         width: 285,
//         height: 167,
//         alt: "이미지"
//       }
//     ]
//   }
//   twitter: {
//       handle: '@handle',
//       site: '@site',
//       cardType: 'summary_large_image',
//   },
// };

function MyApp({Component, pageProps}: AppLayoutProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
          },
        },
      }),
  );

  //레이아웃
  const Layout = Component.layout || ((children) => <>{children}</>);

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <DefaultSeo title="OK케어" />
          <QueryClientProvider client={queryClient}>
            <AuthWrapper>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </AuthWrapper>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
