import {Cookies} from 'react-cookie';

const cookies = new Cookies();

export const setCookieRefreshToken = (refreshToken: string) => {
  return cookies.set('refresh_token', refreshToken, {
    sameSite: 'strict',
    path: '/',
    // expires: expires,
  });
};

export const getCookieRefreshToken = () => {
  return cookies.get('refresh_token');
};

export const removeCookieToken = () => {
  return cookies.remove('refresh_token', {sameSite: 'strict', path: '/'});
};
