import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PURGE} from 'redux-persist';

type Props = {
  info: [];
};

const initialState = {
  info: [],
};

export type AdminReducerState = typeof initialState;

const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    fakerData: (state, action: PayloadAction<Props>) => {
      state.info = action.payload.info;
    },
  },

  extraReducers: (builder) => {
    //초기화하고 싶은 persist state가 있는 slice마다 추가해야한다.
    builder.addCase(PURGE, () => initialState);
  },
});

export const {fakerData} = tempSlice.actions;
export default tempSlice.reducer;
