import {useRouter} from 'next/router';
import {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'stores';
import SvgSpinner from '@assets/svgs/spinner.svg';
import {useQueryClient} from 'react-query';

type AuthProps = {
  children: React.ReactNode;
};

const AuthWrapper: FC<AuthProps> = ({children}) => {
  const {isLoggedIn, accessToken} = useSelector(
    (state: RootState) => state.auth,
  );

  const router = useRouter();

  // 접근 제한이 필요한 페이지 url
  const notPublicPages = ['dashboard', 'admin', 'contents', 'manage', 'user'];

  // 현재 페이지의 url 이름 앞부분
  const currentPage = router.pathname;
  const pageRootName = currentPage.slice(1).split('/')[0];

  // 제한 페이지에 현재페이지가 있는지 체크후 아니면 public 하다고 정의
  const isPublicPage = !notPublicPages.includes(pageRootName);

  useEffect(() => {
    // const refreshToken = getCookieRefreshToken();
    // if (refreshToken === 'undefined') {
    //   onClickLogOut(queryClient, router);
    // }

    // 현재 페이지가 접근제한이 필요한 페이지일때(private)
    if (!isPublicPage) {
      // isPublicPage = false private한 페이지
      if (!isLoggedIn && accessToken == '') {
        // 현재 로그인된 상태가 아니고 && accessToken이 없다면,
        router.push('/auth/login'); // 로그인 페이지로 이동시킴.
      }
    } else {
      // isPublicPage = true public한 페이지
      if (!isLoggedIn && accessToken == '') {
        // 현재 로그인된 상태가 아니고 && accessToken이 없다면,
        router.push('/auth/login'); // 로그인 페이지로 이동시킴.
      } else {
        router.push('/dashboard');
      }
    }
  }, [isLoggedIn]);

  const Loading = () => {
    return (
      <div className="flex h-screen items-center justify-center">
        <SvgSpinner className={`h-6 w-6 animate-spin `} />
      </div>
    );
  };

  if (!isPublicPage) {
    if (!isLoggedIn && accessToken == '') {
      return <Loading />;
    }
    return <>{children}</>;
  } else {
    if (!isLoggedIn && accessToken == '') {
      return <>{children}</>;
    }
    return <Loading />;
  }
};

export default AuthWrapper;
