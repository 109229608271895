import Axios, {AxiosError} from 'axios';
import {setIsLoggedIn} from 'stores/modules/auth';
import {getCookieRefreshToken} from 'utils/Cookie';

///개발 인증서버
const devAuthConf = {
  APP_NAME: 'okcare(인증)',
  API_URL: 'https://auth.okcare.kr:44350',
};

//개발 서버정보
const devConf = {
  APP_NAME: 'okcare(개발)',
  API_URL: 'https://api.okcare.kr:44351',
};

//운영 인증서버
const prodAuthConf = {
  APP_NAME: 'okcare(인증)',
  API_URL: 'https://auth.okcare.kr:44350',
};

//운영 서버정보
const prodConf = {
  APP_NAME: 'okcare(운영)',
  API_URL: 'https://api.okcare.kr:44351',
};

export const baseAuthUrl =
  process.env.NODE_ENV === 'development'
    ? devAuthConf.API_URL
    : prodAuthConf.API_URL;

export const baseUrl =
  process.env.NODE_ENV === 'development' ? devConf.API_URL : prodConf.API_URL;

export const axiosAuth = Axios.create({
  baseURL: baseAuthUrl,
  headers: {
    Accept: 'application/json; charset=UTF-8',
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});

export const axios = Axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json; charset=UTF-8',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

export const apiPath = {
  토큰발급: '/connect/token',

  //관리자
  관리자계정정보: '/api/Admin/MyData',
  관리자계정리스트: '/api/Admin',
  관리자계정등록: '/api/Admin/Signup',
  관리자계정삭제: '/api/Admin',
  관리자계정정보업데이트: '/api/Admin/Update',
  관리자계정아이디중복확인: '/api/Admin/FindId',
  관리자계정비밀번호초기화: '/api/Admin/ResetPassword',

  //정책
  정책리스트: '/api/Admin/Policy',
  정책삭제: '/api/Admin/Policy',
  정책등록: '/api/Admin/Policy/Add',
  정책수정: '/api/Admin/Policy/Update',

  //푸시
  푸시메시지조회: '/api/Admin/Push/Templete',
  푸시메시지등록: '/api/Admin/Push/Templete/Add',
  푸시메시지수정: '/api/Admin/Push/Templete/Update',
  푸시메시지삭제: '/api/Admin/Push/Templete',

  //푸시예약보내기
  푸시예약조회: '/api/Admin/Push',
  푸시예약상세조회: '/api/Admin/Push/Users',
  푸시예약등록: '/api/Admin/Push/Add',
  푸시예약수정: '/api/Admin/Push/Update',
  푸시예약삭제: '/api/Admin/Push',

  // 회원
  회원조회: '/api/Admin/User',
  회원수정: '/api/Admin/User/Update',
  회원휴면설정: '/api/Admin/User/UseFlag',
  회원탈퇴조회: '/api/Admin/User/Secession',
  회원탈퇴: '/api/Admin/User/Secession',

  //검진내역
  국가검진: '/api/Admin/HealthCheckup/National',
  종합검진: '/api/Admin/HealthCheckup/Infinity',

  //구독
  인앱정보: '/api/Admin/InAppPurchase',
  구독정보: '/api/Admin/InAppPurchase/Subscription',

  // 배너
  배너조회: '/api/Admin/Banner',
  배너등록: '/api/Admin/Banner/Add',
  배너삭제: '/api/Admin/Banner',
  배너수정: '/api/Admin/Banner/Update',
  배너이미지수정: '/api/Admin/Banner/Upload',
  배너노출순서수정: '/api/Admin/Banner/Orders/Update',
  // 배너정보조회: '/api/Banner',

  // 팝업
  팝업조회: '/api/Admin/Popup',
  팝업등록: '/api/Admin/Popup/Add',
  팝업삭제: '/api/Admin/Popup',
  팝업수정: '/api/Admin/Popup/Update',
  팝업이미지수정: '/api/Admin/Popup/Upload',

  //공지사항
  공지사항조회: '/api/Admin/Announce',
  공지사항등록: '/api/Admin/Announce/Add',
  공지사항삭제: '/api/Admin/Announce',
  공지사항수정: '/api/Admin/Announce/Update',

  //FAQ
  FAQ조회: '/api/Admin/FAQ',
  FAQ등록: '/api/Admin/FAQ/Add',
  FAQ삭제: '/api/Admin/FAQ',
  FAQ수정: '/api/Admin/FAQ/Update',

  //게시판
  게시판조회: '/api/Admin/Board',
  게시판등록: '/api/Admin/Board/Add',
  게시판삭제: '/api/Admin/Board',
  게시판수정: '/api/Admin/Board/Update',
  게시판이미지수정: '/api/Admin/Board/Upload',

  //1:1문의
  문의조회: '/api/Admin/OneOnOne',
  문의삭제: '/api/Admin/OneOnOne',
  문의수정: '/api/Admin/OneOnOne/Answer',

  //이미지 업로드
  이미지업로드: '/api/Admin/Image/Upload',
};

// api 공통처리 인터셉터
const interceptor = (store: any) => {
  //통신요청
  const {dispatch} = store;

  axios.interceptors.request.use(
    (config: any) => {
      const accessToken = store.getState().auth.accessToken;
      const tokenType = store.getState().auth.tokenType;

      if (!accessToken) {
        return config;
      }
      config.headers['Authorization'] = `${tokenType} ${accessToken}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  //동신응답
  axios.interceptors.response.use(
    async (response) => {
      return Promise.resolve(response);
    },
    async (error: AxiosError) => {
      console.log(error);
      if (
        error.response?.status === 401
        // &&
        // error.response?.statusText === 'Unauthorized'
      ) {
        const refreshToken = getCookieRefreshToken();
        const params = {
          grant_type: 'refresh_token',
          client_id: 'admin.okcare.ro',
          client_secret: 'secret.admin.okcare',
          refresh_token: refreshToken,
        };

        // 토큰재발급
        try {
          const token = await axiosAuth.post(apiPath.토큰발급, params);
          if (
            token.status === 200
            // && token.statusText === 'OK'
          ) {
            const id = store.getState().auth.id;
            dispatch(
              setIsLoggedIn({
                isLoggedIn: true,
                accessToken: token.data.access_token,
                expireTime: token.data.expires_in,
                tokenType: token.data.token_type,
                id: id,
              }),
            );
          }
        } catch (error) {
          console.log('refresh토큰 문제', error);
          dispatch(
            setIsLoggedIn({
              isLoggedIn: false,
              accessToken: '',
              expireTime: '',
              tokenType: '',
              id: '',
            }),
          );
        }
      }
      return Promise.reject(error);
    },
  );
};

export default {
  interceptor,
};
