import {
  Action,
  configureStore,
  EnhancedStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers, Store} from 'redux';
import logger from 'redux-logger';
import {createWrapper, MakeStore} from 'next-redux-wrapper';
import {auth, temp, filter} from './modules';

const persistConfig = {
  key: 'root',
  version: 1,
  // localStorage에 저장합니다.
  storage: storage,
  // 여러개의 reducer 중에 원하는 reducer만 localstorage에 저장합니다.
  whitelist: ['auth'],
  blacklist: ['filter', 'temp'], // 해당하는 모듈만 제외합니다
};

// reducer는 여러개 있을 수 있으므로 하나로 뭉쳐주는 작업이다.
const rootReducer = combineReducers({
  temp: temp,
  auth: auth,
  filter: filter,
});

// persistReducer와 reducer를 뭉쳐놓은 덩어리를 같이 합쳐준다.
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  //리덕스 툴킷에서 기본적으로 직렬화할 경우 데이터의 유실이 발생을 방지하기 위해 뜨는 에러를 직렬화 체크를 막음.
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }).concat(logger)
      : getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
  devTools: process.env.NODE_ENV !== 'production', //  개발자 도구 on/off
});

const setupStore = (context: any): EnhancedStore => store;

const makeStore: MakeStore<any> = (context: any) => setupStore(context);

export const persistor = persistStore(store);

export const wrapper = createWrapper<Store>(makeStore);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// export type ReduxState = ReturnType<typeof rootReducer>;
