import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PURGE} from 'redux-persist';

type Props = {
  isLoggedIn: boolean;
  accessToken: string;
  expireTime: string;
  tokenType: string;
  id: string;
};

const initialState = {
  isLoggedIn: false,
  accessToken: '',
  expireTime: '',
  tokenType: '',
  id: '',
};

export type AuthReducerState = typeof initialState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<Props>) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.accessToken = action.payload.accessToken;
      state.expireTime = action.payload.expireTime;
      state.tokenType = action.payload.tokenType;
      state.id = action.payload.id;
    },
  },
  //초기화하고 싶은 state가 있는 slice마다 아래를 추가해야한다.
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {setIsLoggedIn} = authSlice.actions;
export default authSlice.reducer;
